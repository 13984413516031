import type { DataNode } from '@lama/design-system';
import { Flex, greyPalette, Tree } from '@lama/design-system';
import { MoreVert, Search } from '@mui/icons-material';
import { Tooltip, IconButton, Menu, InputAdornment, TextField } from '@mui/material';
import type { FC } from 'react';
import React, { useCallback, useRef, useState } from 'react';
import { useAsyncFn, useToggle } from 'react-use';
import { useDebouncedCallback } from 'use-debounce';
import { menuProps } from './NoDocumentActions';
import { RenameDocumentModal } from './RenameDocumentModal';
import type { OnMetadataUpdate } from './types';
import { DeleteMenuItem } from './MenuItems/DeleteMenuItem';
import { RenameMenuItem } from './MenuItems/RenameMenuItem';
import { DownloadMenuItem } from './MenuItems/DownloadMenuItem';
import { OpenDocumentMenuItem } from './MenuItems/OpenDocumentMenuItem';
import { DocumentMoveToMenuItem } from './MenuItems/DocumentMoveToMenuItem';

export const moveToMenuProps = {
  anchorOrigin: {
    vertical: 'bottom' as const,
    horizontal: 'right' as const,
  },
  transformOrigin: {
    vertical: 'top' as const,
    horizontal: 'right' as const,
  },
  PaperProps: {
    elevation: 0,
    sx: {
      border: 0,
      borderWidth: '1px',
      borderColor: greyPalette[300],
      boxShadow: '0px 11px 15px 0px #DBDBDB26',
      height: '275px',
      width: '350px',
      overflowY: 'auto',
    },
  },
  PopoverProps: {
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'left',
    },
    ModalProps: {
      disableScrollLock: true,
    },
  },
};

export const DocumentActions: FC<{
  onDelete?: (e: React.MouseEvent) => Promise<void>;
  onOpen?: (e: React.MouseEvent) => void;
  onDownloadDocument?: (e: React.MouseEvent) => void;
  dismissed?: boolean;
  onMetadataUpdate?: OnMetadataUpdate;
  placeholder: string;
  fileName: string;
  documentDeleted?: boolean;
  moveToTreeData?: DataNode[];
  onMoveToClick?: (moveToNode: DataNode) => Promise<void>;
}> = ({
  onDelete,
  onOpen,
  onDownloadDocument,
  onMetadataUpdate,
  placeholder,
  dismissed,
  fileName,
  documentDeleted,
  moveToTreeData,
  onMoveToClick,
}) => {
  const menuRef = useRef<HTMLButtonElement>(null);
  const [menuOpen, toggleMenuOpen] = useToggle(false);
  const [renameModalOpen, toggleRenameModal] = useToggle(false);
  const [moveToMenuOpen, toggleMoveToMenuOpen] = useToggle(false);
  const [moveToFilterValue, setMoveToFilterValue] = useState('');

  const [{ loading: movingFile }, onMoveToInner] = useAsyncFn(
    async (moveToNode: DataNode) => {
      toggleMoveToMenuOpen();
      await onMoveToClick?.(moveToNode);
      toggleMenuOpen();
    },
    [onMoveToClick, toggleMoveToMenuOpen],
  );

  const debouncedSearch = useDebouncedCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setMoveToFilterValue(event.target.value);
  }, 350);

  const stopMenuPropagation = useCallback((e: React.MouseEvent) => {
    e.stopPropagation();
  }, []);

  const onClickMenu = useCallback(
    (event: React.MouseEvent) => {
      stopMenuPropagation(event);
      toggleMenuOpen();
    },
    [toggleMenuOpen, stopMenuPropagation],
  );

  const [{ loading: deletingDocument }, internalOnDelete] = useAsyncFn(
    async (e: React.MouseEvent) => {
      stopMenuPropagation(e);
      await onDelete?.(e);
      toggleMenuOpen();
    },
    [onDelete, toggleMenuOpen, stopMenuPropagation],
  );

  const onClickDocument = useCallback(
    (e: React.MouseEvent) => {
      stopMenuPropagation(e);
      onDownloadDocument?.(e);
    },
    [onDownloadDocument, stopMenuPropagation],
  );

  const onCloseMenu = useCallback(
    (e: React.MouseEvent) => {
      stopMenuPropagation(e);
      toggleMenuOpen();
    },
    [toggleMenuOpen, stopMenuPropagation],
  );

  const onCloseRenameModal = useCallback(() => {
    toggleRenameModal();
    toggleMenuOpen();
  }, [toggleRenameModal, toggleMenuOpen]);

  const onClickMoveTo = useCallback(
    (e: React.MouseEvent) => {
      stopMenuPropagation(e);
      toggleMoveToMenuOpen();
    },
    [stopMenuPropagation, toggleMoveToMenuOpen],
  );

  const onClickRename = useCallback(
    (e: React.MouseEvent) => {
      stopMenuPropagation(e);
      toggleMenuOpen();
      toggleRenameModal();
    },
    [stopMenuPropagation, toggleRenameModal, toggleMenuOpen],
  );

  return (
    <Flex alignItems={'center'} gap={4} onClick={stopMenuPropagation}>
      <Tooltip title={'Actions'}>
        <IconButton onClick={onClickMenu} ref={menuRef} role={'button'} disableRipple>
          <MoreVert />
        </IconButton>
      </Tooltip>
      <Menu
        key={'document-actions-menu'}
        aria-label={'document-actions-menu'}
        anchorEl={menuRef.current}
        open={menuOpen}
        onClose={onCloseMenu}
        onClick={stopMenuPropagation}
        disableScrollLock
        disablePortal
        {...menuProps}
      >
        {!dismissed && onOpen ? <OpenDocumentMenuItem onClick={onOpen} /> : null}
        {onDownloadDocument ? <DownloadMenuItem onClick={onClickDocument} /> : null}
        {!documentDeleted && onMetadataUpdate ? <RenameMenuItem onClick={onClickRename} /> : null}
        {!dismissed && document && !documentDeleted && moveToTreeData?.length ? (
          <DocumentMoveToMenuItem onClick={onClickMoveTo} loading={movingFile} />
        ) : null}
        {document && !documentDeleted ? <DeleteMenuItem onClick={internalOnDelete} loading={deletingDocument} /> : null}
      </Menu>
      <Menu
        key={'document-move-to-menu'}
        aria-label={'document-move-to-menu'}
        anchorEl={menuRef.current}
        open={moveToMenuOpen}
        onClose={toggleMoveToMenuOpen}
        onClick={stopMenuPropagation}
        disableScrollLock
        {...moveToMenuProps}
      >
        <Flex flexDirection={'column'} gap={2}>
          <TextField
            autoFocus
            placeholder={'Search'}
            variant={'standard'}
            // eslint-disable-next-line @typescript-eslint/naming-convention
            sx={{ width: '100%', '.MuiInputBase-root': { px: 1 } }}
            onChange={debouncedSearch}
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position={'start'}>
                  <Search />
                </InputAdornment>
              ),
            }}
          />
          <Tree filterValue={moveToFilterValue} treeData={moveToTreeData} onClickItem={onMoveToInner} autoExpandParent />
        </Flex>
      </Menu>
      <RenameDocumentModal
        open={renameModalOpen}
        onClose={onCloseRenameModal}
        onSubmit={onMetadataUpdate}
        placeholder={placeholder}
        fileName={fileName}
      />
    </Flex>
  );
};
